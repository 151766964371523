import '../../styles/pages/spring-2020/traditions-technology-boost-bee-health.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import { Parallax, Background } from 'react-parallax';
import ScrollAnimation from 'react-animate-on-scroll';
import { withPrefix } from 'gatsby'
import DownArrow from '../../components/downarrow/downarrow.js'
import MoreStories from "../../components/spring-2020-stories/more-stories"
import StoryNav from "../../components/spring-2020-stories/story-nav"
import Related from "../../components/spring-2020-stories/related-stories"

import RomoMcLaughlin from '../../images/spring-2020/Romo-McLaughlin.jpg';
import BeeHive from '../../images/spring-2020/bee-hive.jpg';
import McLaughlinMcNamee from '../../images/spring-2020/McLaughlin-McNamee.jpg';
import beeTeam from '../../images/spring-2020/bee-team.jpg';
import johnson from '../../images/spring-2020/sara-johnson.jpg';

import socialBanner from "../../images/spring-2020/social-banners/arboretum-beehive-research.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Arboretum Buzzes With Beehive Research';

    var pageDesc = 'Most students who come to Cal State Fullerton do not expect their learning experience to include live, stinging insects. But for anthropology graduate student Cailin McLaughlin and alumnus Steve Sagastume ’19 (B.S. electrical engineering), that is exactly what has happened. As members of U-ACRE, Urban Agriculture Community-based Research Experience, the two have regularly suited up in protective gear and conducted research at the apiary on the grounds of Fullerton Arboretum.';

    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${RomoMcLaughlin})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: 'white',
      margin: '0px',
      minHeight: '650px',
      height: '100vh'
    }

    var videoStyles = {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-2020">
        <Helmet>
          <title>{pageTitle}</title>
          <body className="traditions-technology-boost-bee-health" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
            <span className="animated fadeInDown delay-1s meta">Story by Karen Lindell, Photos by U-ACRE and Matt Gush</span>
            <DownArrow color="white" />
          </div>
          <div className="tint"></div>
        </div>
        <ScrollAnimation animateIn="fadeIn">
        <Parallax
          className="full-width bee-video"
          strength={200}>
          <div className="parallax-text" id="intro">
            <p><span className="intro-text">Most students who come to Cal State Fullerton</span> do not expect their learning experience to include live, stinging insects.</p>

            <p>But for anthropology graduate student Cailin McLaughlin and alumnus Steve Sagastume ’19 (B.S. electrical engineering), that is exactly what has happened. As members of U-ACRE, Urban Agriculture Community-based Research Experience, the two have regularly suited up in protective gear and conducted research at the apiary on the grounds of Fullerton Arboretum.</p>
          </div>
          <div className="height" />
          <Background className="custom-bg">
            <video style={videoStyles} id="heroVideo" autoPlay="autoplay" loop muted="muted" poster={withPrefix('/video/bee-farm.jpg')} title="Bee Hive">
              <source src={withPrefix('/video/bee-farm.webm')} type="video/webm; codecs=vp9,vorbis" />
              <source src={withPrefix('/video/bee-farm.mp4')} />
              <track kind="captions" srcLang="en" label="English" src={withPrefix('/video/bee-farm.vtt')} />
            </video>
          </Background>
        </Parallax>
        </ScrollAnimation>
        <article className="wrap small">
          
          <ScrollAnimation animateIn="fadeIn">

          <figure className="align-right align-outside">
            <img src={johnson} alt="Sara Johnson standing in arboretum" />
            <figcaption>Sara Johnson</figcaption>
          </figure>

          <p>Bees are an important part of the arboretum’s Learning Farm ecosystem, as the striped insects pollinate nearby crops such as watermelon and zucchini. The students hope to harvest honey in the future for use at the Monkey Business Cafe and sale by the arboretum.</p>

          <p>Most importantly, says Sara Johnson, professor of anthropology and director of U-ACRE, the apiary provides numerous opportunities for students to engage in research.</p>

          <p>“We look for ways for students to contribute their expertise to solve problems in the ecosystem,” she says. “They learn how to collect data to address research questions.”</p>

          </ScrollAnimation>
        </article>
        <ScrollAnimation animateIn="fadeIn">
        <Parallax
        bgImage={BeeHive}
        bgImageAlt="Bee Hive"
        strength={200}
        className="full-width bee-hive">
          <div className="parallax-text">
            <p>Bee hives require specific internal conditions to prevent colony collapse and optimize honey production. The ideal internal temperature is about 95 degrees with 65% to 70% humidity.</p>
          </div>
          <div className="height" />
        </Parallax>
        </ScrollAnimation>
        <article className="wrap small">
          <ScrollAnimation animateIn="fadeIn">

          <p>With an undergraduate degree in zoology, McLaughlin’s focus is on observing and collecting data on bee behavior. She notes that bees work as a team to vibrate and raise the humidity level as needed. Last fall, though, they had trouble maintaining the right hive conditions when the Santa Ana winds blew through Southern California. Humidity dropped and the hives were at risk for a Varroa mite (Varroa destructor) infestation.</p>
          
          <p>The U-ACRE students knew this was happening because they were collecting both qualitative and quantitative data on the hives.</p>

          <figure className="align-wide">
            <img src={McLaughlinMcNamee} alt="Cailin McLaughlin and Marisa McNamee conduct research with protective gear" />
            <figcaption>Cailin McLaughlin and Marisa McNamee</figcaption>
          </figure>

          <p>With mentoring and assistance from arboretum staff, McLaughlin conducts regular hive inspections. “You can tell how the colony is doing by the noises the bees make and the dances they do,” she explains.</p>

          <Related slug="arboretum-beehive-research" />
          
          <p>The students also gather temperature, relative humidity and atmospheric pressure readings from a sensor that Sagastume constructed and installed in the hive, using his electrical engineering skills and knowledge.</p>

          <p>“The device relays data to a central point and uploads to a website,” he says. “We can track hive conditions to know when to check on the bees for mites.” Sagastume also used the data to create models that capture the relationship between temperature, humidity and honey production. The intent is to make the website publicly available so beekeepers anywhere can model their own honey production.</p>

          <figure className="align-wide">
            <img src={beeTeam} alt="Mitsue Escobar, Jaeda Snow, and Steve Sagastume" />
            <figcaption>Mitsue Escobar, Jaeda Snow and Steve Sagastume</figcaption>
          </figure>

          <p>Despite using technology to monitor the hive, when an infestation does occur, students treat the hive with the least toxic method possible. This suits McLaughlin — and is one of the reasons she is passionate about U-ACRE and its work.</p>

          <p>“I love exploring traditional agricultural methods and knowledge. They help the environment and anyone can do them. We’ve only been doing industrialized farming for 100 years — traditional methods have so much more to offer.”</p>

          <p>For Sagastume, who is currently employed at Eaton in Colorado, U-ACRE gave him a chance to conduct undergraduate research, help the community and apply what he learned in school to real life. “U-ACRE experience helped in my job search too,” he shares. “Employers definitely were interested in hearing how an engineer helped bees.” <span className="end-mark" /></p>

          <p className="learn-more">To learn more about U-ACRE, visit <a href="http://sustainability.fullerton.edu/U-ACRE/" target="_blank">sustainability.fullerton.edu/u-acre</a>.</p>
          </ScrollAnimation>


        </article>
        <MoreStories slug="arboretum-beehive-research" />
        <StoryNav slug="arboretum-beehive-research" />
      </Layout>
    );
  }
} 