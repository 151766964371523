import React from "react"
import {spring2020stories} from './stories';
// import ScrollAnimation from 'react-animate-on-scroll';
import Link from "gatsby-plugin-transition-link/AniLink";

export default class MoreStories extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    var skip = this.props.slug;
    // console.log(skip)

    var currentSlug = this.props.slug;

    var pos = spring2020stories.map(function(story, index) { return story.slug; }).indexOf(currentSlug);

    var currentCategory = spring2020stories[pos].category;

    const list = spring2020stories.map(function(story){
      if (story.slug != skip && story.category == currentCategory) {

        var slug = "/spring-2020/" + story.slug + "/";

        return (
          <Link
          className="related-link"
          cover
          bg="#FF7900"
          direction="up"
          duration={1}
          duration={.75}
          to={slug} >
            <img src={story.thumbnailSmallRect} alt="" className="link-image" />
            <span className="link-text">{story.title}</span>
          </Link>
        )
      }
    })
    

    // console.log(stories);

    return (
      
        <aside className="related-stories">
          <h2>Related Stories</h2>
          {list}
        </aside>
      
    );
  }
} 