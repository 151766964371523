import React from "react"
import {spring2020stories} from './stories';
import ScrollAnimation from 'react-animate-on-scroll';
import Link from "gatsby-plugin-transition-link/AniLink";

export default class MoreStories extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    var skip = this.props.slug;
    // console.log(skip)

    const featuredList = spring2020stories.map(function(story){
      if (story.slug != skip && story.storyType == 'feature') {

        var slug = "/spring-2020/" + story.slug + "/";

        return (
          <li className={story.storyType}>
            <Link
            className="link"
            cover
            bg="#FF7900"
            direction="up"
            duration={1}
            duration={.75}
            to={slug} >
              <img src={story.thumbnail} alt="" className="link-image" />
              <span className="link-text">{story.title}</span>
            </Link>
          </li>
        )
      }
    })

    const list = spring2020stories.map(function(story){
      if (story.slug != skip && story.storyType == 'regular') {

        var slug = "/spring-2020/" + story.slug + "/";

        return (
          <li className={story.storyType}>
            <Link
            className="link"
            cover
            bg="#FF7900"
            direction="up"
            duration={1}
            duration={.75}
            to={slug} >
              <img src={story.thumbnail} alt="" className="link-image" />
              <span className="link-text">{story.title}</span>
            </Link>
          </li>
        )
      }
    })
    

    // console.log(stories);

    return (
      
        <section className="more-stories">
          <ScrollAnimation animateIn="fadeIn">
          <div className="wrap">
            <h2 className="center">More Stories</h2>
            <ul className="related-story-list featured">
              {featuredList}
            </ul>
            <ul className="related-story-list">
              {list}
            </ul>
          </div>
          </ScrollAnimation>
        </section>
      
    );
  }
} 