import React from 'react'
import './downarrow.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll'

const DownArrow = props => {

	var classname = 'down-arrow-button animated bounce slower infinite ';
	classname = classname + props.color;

	return (
		<div className="animated fadeIn delay-2s">
			<AnchorLink href='#intro' className={classname}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><title>Down Arrow</title><path d="M500 758.1l-490-490 26.3-26.2L500 705.6l463.8-463.8 26.3 26.2L500 758.1z"/></svg>
					<span className="down-arrow-button-text">Continue Reading</span>
			</AnchorLink>
		</div>
		// <div className="animated fadeIn delay-3s">
		// 	<button className="down-arrow-button animated bounce slower infinite">
		// 		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><title>Down Arrow</title><path d="M500 758.1l-490-490 26.3-26.2L500 705.6l463.8-463.8 26.3 26.2L500 758.1z"/></svg>
		// 		<span className="down-arrow-button-text">Continue Reading</span>
		// 	</button>
		// </div>
	)
}
export default DownArrow