import React from "react"
import {spring2020stories} from './stories';
import Link from "gatsby-plugin-transition-link/AniLink";
import { TransitionPortal } from "gatsby-plugin-transition-link";
import Icon from "../icons"

export default class StoryNav extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    var currentSlug = this.props.slug;

    var pos = spring2020stories.map(function(story, index) { return story.slug; }).indexOf(currentSlug);

    var prevPos = pos - 1;
    var nextPos = pos + 1;

    if (pos == 0) {
      var prevPos = 7;
      var nextPos = pos + 1;
    } else if (pos == 7) {
      var prevPos = pos - 1;
      var nextPos = 0;
    }

    // console.log(stories[prevPos].slug);
    // console.log(stories[nextPos].slug);

    var prevSlug = "/spring-2020/" + spring2020stories[prevPos].slug + "/";
    var prevTitle = spring2020stories[prevPos].title;
    var prevImage = spring2020stories[prevPos].thumbnailSmall;

    var nextSlug = "/spring-2020/" + spring2020stories[nextPos].slug + "/";
    var nextTitle = spring2020stories[nextPos].title;
    var nextImage = spring2020stories[nextPos].thumbnailSmall;

    return (
        <TransitionPortal level="top">
        <Link
        key={prevSlug}
        className="story-nav previous-story"
        cover
        bg="#FF7900"
        direction="right"
        duration={1}
        title="Go to previous story"
        to={prevSlug} >
          <Icon name="arrow-left" alt="Previous Story" />
          <div className="hidden-story-info">
            <img src={prevImage} alt="" className="hidden-image" />
            <span className="hidden-title">{prevTitle}</span>
          </div>
        </Link>
        <Link
        key={nextSlug}
        className="story-nav next-story"
        cover
        bg="#FF7900"
        direction="left"
        duration={1}
        title="Go to next story"
        to={nextSlug} >
          <Icon name="arrow-right" alt="Next Story" />
          <div className="hidden-story-info">
            <img src={nextImage} alt="" className="hidden-image" />
            <span className="hidden-title">{nextTitle}</span>
          </div>
        </Link>
        </TransitionPortal>
      
    );
  }
} 